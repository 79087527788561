import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withMixpanel } from 'react-mixpanel-browser'
import mixpanel from 'mixpanel-browser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, IconButton, Button } from '@material-ui/core'

import ImageOkyApp from '../../../static/image/gatsby-icon-white.svg'
import axios from 'axios'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { getUser } from '../../services/auth'
import { ClevertapTracking } from '../../utils/clevertap'

function ModalHel({ openModalHelp, setOpenModalHelp, callBackClickCallOky }) {
  const useStyles = makeStyles(theme => ({
    MuiContainerChat: {
      '& .MuiDialog-paper': {
        borderRadius: '30px 30px 0 0',
        width: '30%',
        position: 'fixed',
        bottom: 0,
        margin: 0,
        height: '537px',
        animation: 'scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards',
      },
      [theme.breakpoints.down('sm')]: {
        '& .MuiDialog-paper': {
          borderRadius: '30px 30px 0 0',
          width: '100%',
          position: 'fixed',
          bottom: 0,
          margin: 0,
          height: '537px',
          animation: 'scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards',
        },
      },
    },
    //   margin: 'auto',
    //   width: '333px',
    //   height: 438,
    //   minHeight: 438,
    //   backgroundColor: 'transparent',
    //   boxShadow:
    //     '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
    //   borderRadius: '24px',
    //   '& .MuiPaper-root': {
    //     margin: '0 !important',
    //     width: '100%',
    //     minHeight: 438,
    //     backgroundColor: '#FFFFFF',
    //     boxShadow:
    //       '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
    //     borderRadius: '24px',
    //   },
    //   '& .MuiDialogContent-root': {
    //     padding: '0 !important',
    //   },
    //   '& .MuiDialogTitle-root': {
    //     padding: '0 !important',
    //     backgroundColor: '#F5F5F5',
    //     height: '88px',
    //   },
    //   '& .MuiDialogActions-root': {
    //     width: '95%',
    //   },
    // },
    containerRedes: {
      backgroundColor: '#F5F5F5',
      padding: '14px 0 0 0',
      height: '88px',
    },
    MuiContainerText: {
      width: '100%',
      alignItems: 'center',
      textDecoration: 'none',
    },
    MuiImage: {
      textAlign: 'center',
    },
    MuiImageInsideWpp: {
      margin: 'auto',
      width: '40px',
      height: '40px',
      backgroundColor: 'hsl(269, 57%, 34%)',
      borderRadius: '25px',
    },
    MuiIcon: {
      marginTop: '8px',
      fontSize: '25px',
      color: 'white',
    },
    MuiImageInsideEmail: {
      margin: 'auto',
      width: '40px',
      height: '40px',
      backgroundColor: 'hsl(269, 57%, 34%)',
      borderRadius: '25px',
    },
    MuiImageInside: {
      margin: 'auto',
      width: '40px',
      height: '40px',
      backgroundColor: 'hsl(269, 57%, 34%)',
      borderRadius: '25px',
    },
    MuiTextTitle: {
      fontSize: '11px',
      textAlign: 'center',
      marginTop: 8,
    },
    MuiImageInsideSug: {
      margin: 'auto',
      width: '40px',
      height: '40px',
      backgroundColor: 'hsl(269, 57%, 34%)',
      borderRadius: '25px',
    },
    MuiBodyContentTitleChat: {
      textAlign: 'center',
      justifyContent: 'center',
    },
    MuiBodyTitle: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: '800',
      fontSize: '24px',
      lineHeight: '150%',
      letterSpacing: '0.15px',
      color: '#552588',
      width: '100% !important',
      marginTop: 31,
      marginBottom: 15,
    },
    MuiBackGroundImage: {
      width: 108,
      height: 174,
    },
    MuiFooterContentAdjunt: {
      margin: 'auto',
      paddingLeft: 10,
    },
    MuiFooterContentSent: {
      margin: 'auto',
      paddingLeft: 10,
    },
    borderC: {
      border: '1px solid #E0E0E0',
      borderRadius: 36,
      padding: '0px 10px !important',
      marginBottom: 18,
    },
    MuiFooterAdjunt: {
      color: '#757575',
      fontSize: 24,
      paddingBottom: 10,
    },
    MuiFooterSent: {
      color: '#552588',
      paddingBottom: 10,
      fontSize: 24,
    },
    iFrame: {
      height: '334px !important',
      '@media (max-width:600px)': {
        height: '334px !important',
      },
      '@media (min-width:320px)': {
        height: '334px !important',
      },
    },
    title: {
      color: '#552588',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '24px',
      lineHeight: '29px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      letterSpacing: '0.15px',
      height: '61px !important',
    },
    cardPaymentMethodsTitle: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '160%',
      letterSpacing: 0.15,
      color: '#552588',
      width: '100%',
      textAlign: 'left',

      padding: '30px 16px 24px 4px',
    },
    cardPaymentMethodsCloseButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: '#552588',
    },
    MuiDialogContent: {
      padding: '0px 20px !important',
    },
    MuiSubTitleHelp: {
      color: 'rgba(0, 0, 0, 0.54)',
      textAlign: 'center',
      fontFamily: 'Lato',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '143%',
      letterSpacing: '0.15px',
      margin: '26px 20px',
    },
    MuiButtonOpt: {
      borderRadius: 32,
      background: '#552588',
      display: 'flex',
      width: '90%',
      height: '47px',
      flexDirection: 'column',
      textTransform: 'none',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      padding: '0px 24px',
      color: '#ffffff',
      border: 'none',
      margin: '20px auto',
      position: 'relative',
      top: '8%',
      fontSize: '18px',
    },
    MuiDescription: {
      color: '#552588',
      textAlign: 'center',
      fontFamily: 'Lato',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '143%',
      letterSpacing: '0.15px',
      position: 'relative',
      top: '18%',
    },
  }))
  const classes = useStyles()
  mixpanel.init(mixpanel)

  const gatsbyUser = getUser()
  const [canTrack] = React.useState(!!mixpanel)
  const [popupPhone, setPopupPhone] = React.useState(true)

  useEffect(() => {
    if (gatsbyUser?.id && gatsbyUser?.user?.status !== 3) {
      setPopupPhone(false)
    }
  }, [gatsbyUser])

  const onClose = () => {
    setOpenModalHelp(false)
  }

  const handlerCallEvent = () => {
    if (canTrack && typeof window !== `undefined`) {
      mixpanel.track(
        'Error Message Help Channel',
        {
          'Selected Channel': 'Call to OKY',
        },
        response => {
          console.log('Track response:', response)
        },
      )
      ClevertapTracking('Error Message Help Channel', {
        'Selected Channel': 'Call to OKY',
      })
    }
    if (gatsbyUser) {
      setPopupPhone(true)

      const phoneNumber =
        gatsbyUser?.user?.country?.id === '2'
          ? gatsbyUser?.user?.phoneNumber
          : `00${gatsbyUser?.user?.country?.callingCode +
              gatsbyUser?.user?.phoneNumber}`
      const url = `${process.env.API_SMART_CALL_CENTER_URL}?num1=${phoneNumber}`
      const data = {}

      try {
        // eslint-disable-next-line promise/catch-or-return
        axios.post(url, data).then(
          response => {},
          error => {
            console.log(error)
          },
        )
      } catch (error) {
        console.log(error)
      }
    }
  }

  const DialogTitle = props => {
    const { children, onClose, ...other } = props
    return (
      <MuiDialogTitle disableTypography {...other}>
        <Typography variant="h6" className={classes.cardPaymentMethodsTitle}>
          {children}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.cardPaymentMethodsCloseButton}
          onClick={onClose}
        >
          <FontAwesomeIcon
            icon="fa-solid fa-xmark"
            style={{
              color: '#552588',
              width: 40,
            }}
          />
        </IconButton>
      </MuiDialogTitle>
    )
  }

  const handleWhatsapp = () => {
    if (canTrack && typeof window !== `undefined`) {
      mixpanel.track('Error Message Help Channel', {
        'Selected Channel': 'Whatsapp',
      })
      ClevertapTracking('Error Message Help Channel', {
        'Selected Channel': 'Whatsapp',
      })
    }
    setOpenModalHelp(false)
    window.open(
      `
      https://api.whatsapp.com/send?phone=18884230471&text=Necesito%20ayuda`,
      '_blank',
    )
  }

  const handleLlamarOky = () => {
    if (canTrack && typeof window !== `undefined`) {
      mixpanel.track('Error Message Help Channel', {
        'Selected Channel': 'OKY Call',
      })
      ClevertapTracking('Error Message Help Channel', {
        'Selected Channel': 'OKY Call',
      })
    }
    if (callBackClickCallOky) {
      callBackClickCallOky()
    }
    setOpenModalHelp(false)
    window.location.href = `tel:+18884230471`
  }

  return (
    <Dialog open={openModalHelp} className={classes.MuiContainerChat}>
      <DialogTitle className={classes.title} onClose={onClose}>
        Ayuda
      </DialogTitle>
      <DialogContent className={classes.MuiDialogContent}>
        <Typography
          variant="body2"
          gutterBottom
          component="div"
          className={classes.MuiSubTitleHelp}
        >
          Elige como te podemos ayudar
        </Typography>
        <Button
          variant="contained"
          className={classes.MuiButtonOpt}
          onClick={handleWhatsapp}
        >
          <FontAwesomeIcon
            size="xl"
            className={classes.MiuIcoButtonOpt}
            icon="fa-brands fa-whatsapp"
            style={{
              color: '#ffffff',
            }}
          />
          {` Whatsapp `}
          <FontAwesomeIcon
            icon="fa-solid fa-angle-right"
            style={{
              color: '#ffffff',
            }}
          />
        </Button>
        <Button
          variant="contained"
          className={classes.MuiButtonOpt}
          onClick={() => {
            handlerCallEvent()
          }}
          disabled={popupPhone}
        >
          <img
            className={classes.MiuIcoButtonOpt}
            src={ImageOkyApp}
            srcSet={ImageOkyApp}
            alt={'OKY'}
            loading="lazy"
          />
          {` OKY te llama `}
          <FontAwesomeIcon
            icon="fa-solid fa-angle-right"
            style={{
              color: '#ffffff',
            }}
          />
        </Button>
        <Button
          variant="contained"
          className={classes.MuiButtonOpt}
          onClick={handleLlamarOky}
        >
          <FontAwesomeIcon
            size="lg"
            className={classes.MiuIcoButtonOpt}
            icon="fa-solid fa-phone"
            style={{
              color: '#ffffff',
            }}
          />
          {` Llama a OKY `}
          <FontAwesomeIcon
            icon="fa-solid fa-angle-right"
            style={{
              color: '#ffffff',
            }}
          />
        </Button>
        <Typography
          variant="body2"
          gutterBottom
          component="div"
          className={classes.MuiDescription}
        >
          Lamentamos el inconveniente, en seguida te ayudamos.
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

export default withMixpanel(ModalHel)
