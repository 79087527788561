import { InMemoryCache, makeVar } from '@apollo/client'
import { getUser } from './services/auth'

const initialShoppingCart = JSON.stringify({
  vouchers: [
    // {
    //   id: '1',
    //   name: 'McMenú Desayuno Tradicional',
    //   amount: 5,
    //   primaryImgURL: 'https://img.oky.app/dev_img/giftcardEsp136.',
    //   quantity: 1,
    // },
  ],
  subTotal: 0,
  sendingFee: 0,
  total: 0,
  discountAmount: 0,
})

export const hasWindow = typeof window !== 'undefined'
const initialLoginState = {
  phoneNumber: '',
  countryId: 2,
  indicativo: '',
  countrySchema: '',
}
export const loginStateVar = makeVar(initialLoginState)
export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn: {
          read: () => {
            const isLoggedIn = isLoggedInVar()
            return isLoggedIn
          },
        },
        loginState: {
          read: () => {
            const loginState = loginStateVar()
            return loginState
          },
        },
        selectedCountry: {
          read: () => {
            const selectedCountry = selectedCountryVar()
            return selectedCountry
          },
        },
        selectedCountryValidated: {
          read: () => {
            const selectedCountry = selectedCountryValidatedVar()
            return selectedCountry
          },
        },
        TopUpData: {
          read: () => {
            const TopUpData = TopUpDataVar()
            return TopUpData
          },
        },
        dataPantone: {
          read: () => {
            const dataPantone = dataPantoneVar()
            return dataPantone
          },
        },
        selectedDefaultCountry: {
          read: () => {
            const selectedDefaultCountry = defaultCountryVar()
            return selectedDefaultCountry
          },
        },
        selectedState: {
          read: () => {
            const selectedState = selectedStateVar()
            return selectedState
          },
        },
        currentVoucher: {
          read: () => currentVoucherVar(),
        },
        selectedTag: {
          read: () => selectedTagVar(),
        },
        selectedFeatured: {
          read: () => selectedFeaturedVar(),
        },
        selectedPartner: {
          read: () => selectedPartnerVar(),
        },
        showCart: {
          read: () => showCartVar(),
        },
        shoppingCart: {
          read: () => JSON.parse(shoppingCartVar()),
        },
        showCheckout: {
          read: () => showCheckoutVar(),
        },
        userInformation: {
          read: () => userInformationVar(),
        },
        product: {
          read: () => productVar(),
        },
        selectedContact: {
          read: () => selectedContactVar(),
        },
        selectedCoupon: {
          read: () => selectedCouponVar(),
        },
        showVoucherifyUser: {
          read: () => showVoucherifyUserVar(),
        },
        isWebView: {
          read: () => isWebViewVar(),
        },
        osApp: {
          read: () => osAppVar(),
        },
      },
    },
  },
  // dataIdFromObject: object => `${object.__typename}:${object.id}`,
})

export const isLoggedInVar = makeVar(
  (hasWindow && !!getUser()?.user && getUser()?.user.status !== 3) || null,
)

export const selectedCountryVar = makeVar(
  (hasWindow &&
    JSON.parse(
      window.localStorage.getItem('selectedCountry') === 'undefined'
        ? null
        : window.localStorage.getItem('selectedCountry'),
    )) ||
    null,
)
export const selectedCountryValidatedVar = makeVar(
  (hasWindow &&
    JSON.parse(
      window.localStorage.getItem('selectedCountryValidated') === 'undefined'
        ? null
        : window.localStorage.getItem('selectedCountryValidated'),
    )) ||
    null,
)
export const TopUpDataVar = makeVar(
  (hasWindow &&
    JSON.parse(
      window.localStorage.getItem('TopUpData') === 'undefined'
        ? null
        : window.localStorage.getItem('TopUpData'),
    )) ||
    null,
)
export const dataPantoneVar = makeVar(
  (hasWindow &&
    JSON.parse(
      window.localStorage.getItem('dataPantone') === 'undefined'
        ? null
        : window.localStorage.getItem('dataPantone'),
    )) ||
    null,
)
export const productVar = makeVar(
  (hasWindow &&
    JSON.parse(
      window.localStorage.getItem('_producto_') === 'undefined'
        ? null
        : window.localStorage.getItem('_producto_'),
    )) ||
    null,
)

export const selectedStateVar = makeVar(
  (hasWindow &&
    JSON.parse(
      window.localStorage.getItem('selectedState') === 'undefined'
        ? null
        : window.localStorage.getItem('selectedState'),
    )) ||
    null,
)

export const currentVoucherVar = makeVar(null)

export const defaultCountryVar = makeVar(
  (hasWindow && window.localStorage.getItem('defaultCountry')) || null,
)
export const selectedTagVar = makeVar(
  (hasWindow && window.localStorage.getItem('selectedTag')) || null,
)

export const selectedFeaturedVar = makeVar(
  (hasWindow && window.localStorage.getItem('selectedFeatured')) || null,
)

export const selectedPartnerVar = makeVar(
  (hasWindow && window.localStorage.getItem('selectedPartner')) || null,
)

export const showCartVar = makeVar(false)

export const shoppingCartVar = makeVar(
  (hasWindow && window.localStorage.getItem('shoppingCart')) ||
    initialShoppingCart,
)

export const userInformationVar = () =>
  window.localStorage.getItem('gatsbyUser') !== 'undefined'
    ? makeVar(
        (hasWindow && JSON.parse(window.localStorage.getItem('gatsbyUser'))) ||
          null,
      )
    : window.localStorage.removeItem('gatsbyUser')

export const selectedContactVar = makeVar(
  (hasWindow &&
    window.localStorage.getItem('contact') !== 'undefined' &&
    JSON.parse(window.localStorage.getItem('contact'))) ||
    null,
)

export const selectedCouponVar = makeVar(
  (hasWindow && JSON.parse(window.localStorage.getItem('selectedCoupon'))) ||
    null,
)

export const showVoucherifyUserVar = makeVar(
  (hasWindow &&
    JSON.parse(window.localStorage.getItem('showVoucherifyUser'))) ||
    null,
)

export const isWebViewVar = makeVar(
  (hasWindow && JSON.parse(window.localStorage.getItem('isWebView'))) || null,
)

export const osAppVar = makeVar(
  (hasWindow && JSON.parse(window.localStorage.getItem('osApp'))) || null,
)

export const showCheckoutVar = makeVar(false)
