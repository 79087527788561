export class ConsoleLogger {
  constructor() {
    this.title = `${process.env.OKY_CONSOLE_LOGS_TITLE || 'OKY'}`
    this._group = console.group.bind(console)
    this._groupEnd = console.groupEnd.bind(console)
    this._log = console.log.bind(console)
    this._warn = console.warn.bind(console)
    this._error = console.error.bind(console)
  }

  getStyles({ backgroundColor, color }) {
    return [
      `background: ${backgroundColor || '#552588'}`,
      'border-radius: 3px',
      `color:  ${color || '#FFFFFF'}`,
      'font-weight: bold',
      'padding: 2px 5px',
    ].join(';')
  }

  getArgs(...args) {
    const prefix = `[${new Date().toLocaleString()}]`
    if (typeof args[0] === 'string') {
      args[0] = `${prefix} ${args[0]}`
    } else {
      args.unshift(prefix)
    }
    return args
  }

  print(call, ...args) {
    if (process.env.OKY_CONSOLE_LOGS_ENABLE === 'TRUE') {
      this._group(
        `%c${this.title} ${process.env.NODE_ENV}:`,
        this.getStyles({ backgroundColor: '#552588' }),
      )
      const _args = this.getArgs(...args)
      call(..._args)
      this._groupEnd()
    }
  }

  log(...args) {
    this.print(() => {
      this._log(args)
    })
  }

  warn(...args) {
    this.print(() => {
      this._warn(args)
    })
  }

  error(...args) {
    this.print(() => {
      this._error(args)
    })
  }
}

export default new ConsoleLogger()
